export default async function pdfToImage(file, dpi) {
  const url = URL.createObjectURL(file)

  const pdfjsLib = await import(/* webpackChunkName: 'vendors/pdfjs-dist' */ 'pdfjs-dist/webpack')

  const pdf = await pdfjsLib.getDocument({
    url: url,
    cMapUrl: '/pdfjs/cmaps/',
    cMapPacked: true
  }).promise

  const page = await pdf.getPage(1)

  const scale = dpi / 72
  const viewport = page.getViewport({ scale: scale })

  const canvas = document.createElement('canvas')
  canvas.width = viewport.width
  canvas.height = viewport.height

  const renderContext = {
    canvasContext: canvas.getContext('2d'),
    viewport: viewport
  }
  await page.render(renderContext).promise

  return new Promise(resolve => {
    canvas.toBlob((blob) => {
      blob.name = file.name + '.jpg'
      resolve(blob)
    }, 'image/jpeg', 0.95)
  })
}
