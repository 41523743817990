import kindOf from 'kind-of'

export default class {
  static text(error: any) {
    if(kindOf(error) == 'null' || kindOf(error) == 'undefined' || kindOf(error) == 'boolean') {
      return ''
    }
    if(kindOf(error) != 'object') {
      return error.toString()
    }

    if ('message' in error) {
      return error.message
    }
    if (!error.status && error.type && error.type === 'error') {
      return '画像の読み込みに失敗しました。'
    }
    if (error.viewerStatus && error.viewerStatus == 999) {
      return 'データ不正のため表示できません。'
    }

    let text
    switch (error.status) {
      case 400:
        text = '認証に失敗しました。'
        break
      case 401:
      case 403:
      case 405:
      case 406:
      case 407:
        text = 'アクセスが拒否されました。'
        break
      case 404:
        text = 'データが取得できませんでした。'
        break
      case 408:
        text = 'タイムアウト。'
        break
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 505:
      case 506:
      case 507:
      case 508:
      case 509:
      case 510:
        text = 'サーバーエラーのため読み込みに失敗しました。'
        break
      default:
        text = '読み込みに失敗しました。リロードしてください。'
        break
    }

    return `${text}(${error.status})`
  }
}
