// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.modal-backdrop {
    opacity:0.5 !important;
}
[v-cloak] {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/license-qr.vue"],"names":[],"mappings":";AAsGA;IACA,sBAAA;AACA;AAEA;EACA,aAAA;AACA","sourcesContent":["<template>\n  <div>\n    <div @click=\"showModal\">\n      <thumbnail :image-src=\"imageUrl\" :img-data=\"{ key: null }\"/>\n      <img src='@/images/common/zoom-icon.png' class=\"zoom\">\n    </div>\n\n    <b-modal ref=\"qr\" size=\"sm\" :title=\"title\" hide-footer>\n      <div class=\"text-center\">\n        <img :src=\"imageUrl\" />\n        <p class=\"qr-key\">\n          {{ license }}\n        </p>\n      </div>\n    </b-modal>\n  </div>\n</template>\n\n<script>\nimport QRCode from 'qrcode'\n\nimport thumbnail from './thumbnail'\n\nexport default {\n  components: { thumbnail },\n\n  props: [ 'license' ],\n\n  data() {\n    return {\n      title: '',\n      imageUrl: ''\n    }\n  },\n\n  computed: {\n    url() {\n      return `nurveviewer://nurve.jp/list_home?license=${this.license}`\n    }\n  },\n\n  methods: {\n    createQRImage() {\n      return QRCode.toDataURL(this.url, {\n        width: 200,\n        height: 200,\n        margin: 2,\n        errorCorrectionLevel: 'Q',\n        scale: 2,\n        type: 'png',\n        mode: 'byte'\n      })\n    },\n\n    showModal() {\n      this.$refs['qr'].show()\n    }\n  },\n\n  async beforeMount() {\n    this.imageUrl = await this.createQRImage()\n  }\n}\n</script>\n\n<style lang=\"scss\" scoped>\n  .place-qr {\n    width: 80px;\n    position: relative;\n\n    .thumbnail {\n      cursor: pointer;\n      width: 60px;\n      display: inline-block;\n      vertical-align: middle;\n    }\n\n    .zoom {\n      cursor: pointer;\n      position: absolute;\n      right: 2px;\n      bottom: 2px;\n    }\n\n    .btn {\n      width: 74px;\n      margin-top: 2px;\n      margin-bottom: 2px;\n    }\n  }\n</style>\n\n<style>\n  .modal-backdrop {\n      opacity:0.5 !important;\n  }\n\n  [v-cloak] {\n    display: none;\n  }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
