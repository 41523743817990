import { ImageQuality } from '~/group_calls/types'

declare const gon

export default class GonReader {
  static get slug() {
    return gon.bodyParams.slug
  }

  static get floor_map_enable(): boolean {
    return gon.bodyParams.floor_map_enable
  }

  static get organization_id(): number {
    return gon.bodyParams.organization_id
  }

  static get organization_group_id(): number {
    return gon.bodyParams.organization_group_id
  }

  static get is_show_floor_plan(): boolean {
    return gon.bodyParams.is_show_floor_plan
  }

  static get image_quality(): ImageQuality | undefined {
    return gon.bodyParams.image_quality
  }

  static get acceptable_angle_sender(): string {
    return gon.bodyParams.acceptable_angle_sender
  }

  static get websocket_uri(): string {
    return gon.bodyParams.websocket_uri
  }

  static get anywhere_device_id() {
    return gon.bodyParams.anywhere_device_id
  }

  static get draw_radar() {
    return gon.bodyParams.draw_radar
  }

  static get video_repeat() {
    return gon.bodyParams.video_repeat
  }

  static get draw_line() {
    return gon.bodyParams.draw_line
  }

  static get footer_controls_enable() {
    return gon.bodyParams.footer_controls_enable
  }

  static get back_room_enable() {
    return gon.bodyParams.back_room_enable
  }
}
