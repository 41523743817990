/**
 * FIXME: 操作画面はビューワー部分とその他のL字部分が分離されているので、
 *        ストアもそれに合わせて分離することを検討する
 */
import * as THREE from 'three'
import TWEEN from '@tweenjs/tween.js'
import TweenPromisify from '~/shared/tween-promisify'
import ErrorMessage from '../models/error_message'
import ViewerConst from '../viewer_const'
import GonReader from '~/vr_controls/gon_reader'
import BowserAccessor from '~/use_case_supports/states/bowser_accessor'

export default {
  namespaced: true,

  state: {
    controlMenu: {
      height: 200,
      opacity: 1.0,
    },

    floorplan: {
      opacity: 1.0,
    },

    connection: {
      connected: false,
      deviceName: null,
    },

    connectionResult: {
      show: false,
      title: '',
      message: '',
      transition: false,
    },

    download: {
      progress: '',
    },

    error: {
      message: '',
    },

    logger: {
      // できるだけ重複したデータを送らないように送ったものを保存
      sendedIDs: [],
    },

    isSyncAngleView: false,
    gyroRotation: false,

    mini: false,

    slug: '',
    organizationId: '',
    organizationGroupId: '',
  },

  getters: {
    isSyncAngleView(state) {
      return state.isSyncAngleView
    },

    footerControlHeight(state, getters, rootState, rootGetters) {
      const openedHeight = BowserAccessor.mobile ? 150 : 200

      return { opened: openedHeight, closed: 0 }
    },

    floorPlanSize(state, getters, rootState, rootGetters) {
      if (BowserAccessor.mobile) {
        return { width: 200, height: 150 }
      }

      return state.mini ? { width: 280, height: 210 } : { width: 300, height: 300 }
    },

    slug(state) {
      return state.slug
    },

    organizationId(state) {
      return state.organizationId
    },

    organizationGroupId(state) {
      return state.organizationGroupId
    },

    loggerSendedIDs(state) {
      return state.logger.sendedIDs
    },
  },

  mutations: {
    setConnectionConnected(state, connected) {
      state.connection.connected = connected
    },
    setConnectionDeviceName(state, deviceName) {
      state.connection.deviceName = deviceName
    },

    setConnectionResult(state, payload) {
      if (payload) {
        state.connectionResult.show = true
        state.connectionResult.title = payload.title
        state.connectionResult.message = payload.message
        state.connectionResult.transition = payload.transition || false
      } else {
        state.connectionResult.show = false
      }
    },

    setDownloadProgress(state, payload) {
      state.download.progress = payload
    },

    setErrorMessage(state, payload) {
      state.error.message = ErrorMessage.text(payload)
    },

    setControlMenu(state, payload) {
      state.controlMenu.height = payload.height
      state.controlMenu.opacity = payload.opacity

      state.floorplan.opacity = GonReader.is_show_floor_plan ? payload.opacity : 0.0
    },

    showFloorPlan(state, payload) {
      state.floorplan.opacity = GonReader.is_show_floor_plan && payload ? 1.0 : 0.0
    },

    setSyncAngleView(state, isSyncAngleView) {
      state.isSyncAngleView = isSyncAngleView
    },

    setMiniMode(state, payload) {
      state.mini = payload
    },

    setSlug(state, paylod) {
      state.slug = paylod
    },

    setOrganizationId(state, payload) {
      state.organizationId = payload
    },

    setOrganizationGroupId(state, payload) {
      state.organizationGroupId = payload
    },

    setGyroRotation(state, payload) {
      state.gyroRotation = payload
    },

    pushLoggerSendedIDs(state, paylod) {
      state.logger.sendedIDs.push(paylod.join(','))
    },
  },

  actions: {
    toggleGyroRotation({ dispatch, commit, state }) {
      commit('setGyroRotation', !state.gyroRotation)
    },
  },
}
