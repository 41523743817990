import Device from '~/vr_controls/models/device'

import device_commands_module from '~/admin/device_commands/store_module'
import createLogger from '~/common/logger/logger-factory'

export default [
  {
    path: '/places/:place_id/maps/:map_id/tag_editors/items/:id/edit',
    component: () => import(
      /* webpackChunkName: 'components/maps/tag_editors/items/edit' */
      '~/maps/tag_editors/items/edit'
    ),
    props: gon.vueProps
  },
  {
    path: '/places',
    component: () => import(
      /* webpackChunkName: 'components/index/places-index' */
      '~/places/index/places-index'
    ),
    props: gon.vueProps
  },
  {
    path: '/places/:id/delegate_place/edit',
    component: () => import(
      /* webpackChunkName: 'components/places/delegate_places/delegate-place-edit' */
      '~/places/delegate_places/delegate-place-edit'
    ),
    props: gon.vueProps
  },
  {
    path: '/organizations/:id/settings',
    component: () => import(
      /* webpackChunkName: 'components/organization_settings/show/organization-settings-show' */
      '~/organization_settings/show/organization-settings-show'
    ),
    props: gon.vueProps
  },
  {
    path: '/admin/investigator',
    component: () => import(
      /* webpackChunkName: 'components/admin/investigators/show' */
      '~/admin/investigators/show'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_checklists/accounts/:id/edit',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_checklists/accounts/edit' */
      '~/anywhere_checklists/accounts/edit'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_stores/staff/edit',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_staffs/edit' */
      '~/anywhere_staffs/edit'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_checklists/:secret',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_checklists/conversions/index' */
      '~/anywhere_checklists/conversions/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_checklists/:secret/conversions/:id',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_checklists/conversions/show' */
      '~/anywhere_checklists/conversions/show'
    ),
    props: gon.vueProps
  },
  {
    path: '/cache_medias',
    component: () => import(
      /* webpackChunkName: 'components/cache_medias/index' */
      '~/cache_medias/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/usage_diagnostics',
    component: () => import(
      /* webpackChunkName: 'components/usage_diagnostics/index' */
      '~/usage_diagnostics/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/usage_diagnostics/by_one_step',
    component: () => import(
      /* webpackChunkName: 'components/usage_diagnostics/by_one_step' */
      '~/usage_diagnostics/by-one-step'
    ),
    props: gon.vueProps
  },
  {
    path: '/reports/crewl_pv',
    component: () => import(
      /* webpackChunkName: 'components/reports/show' */
      '~/reports/show'
    ),
    props: { graphName: 'CrewlPvsQuery' },
  },
  {
    path: '/reports/panorama_pv',
    component: () => import(
      /* webpackChunkName: 'components/reports/show' */
      '~/reports/show'
    ),
    props: { graphName: 'PanoramaPvsQuery' },
  },
  {
    path: '/reports/panorama_pv_daily',
    component: () => import(
      /* webpackChunkName: 'components/reports/show' */
      '~/reports/show'
    ),
    props: { graphName: 'PanoramaPvDailiesQuery' },
  },
  {
    path: '/anywhere_stores/purposes/new',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_stores/purposes/edit' */
      '~/anywhere_stores/purposes/edit'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_stores/purposes/:id/edit',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_stores/purposes/edit' */
      '~/anywhere_stores/purposes/edit'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_stores/:id/config/edit',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_stores/config/edit' */
      '~/anywhere_stores/configs/edit'
    ),
    props: {}
  },
  {
    path: '/anywhere_stores/:id/signage/edit',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_stores/signages/edit' */
      '~/anywhere_stores/signages/edit'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_stores/personal_informations/new',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_stores/personal_informations/new' */
      '~/anywhere_stores/personal_informations/new'
    ),
    props: gon.vueProps
  },
  {
    path: '/anywhere_stores/remote_inspector',
    component: () => import(
      /* webpackChunkName: 'components/anywhere_stores/remote_inspector/index' */
      '~/anywhere_stores/remote_inspector/index'
    ),
    props: gon.anywhereRemoteInspectorProps
  },
  {
    path: '/vr_control/:id/embed',
    component: () => import(
      /* webpackChunkName: 'components/vr_controls/ui/vr-control-embed' */
      '~/vr_controls/ui/vr-control-embed'
    ),
    props: () => {
      gon.vueProps.devices = gon.vueProps.devices.map(d => new Device(d))
      return gon.vueProps
    }
  },
  {
    path: '/places/:place_id/maps/:map_id/points',
    component: () => import(
      /* webpackChunkName: 'components/places/map/points' */
      '~/components/place-map-point-item'
    ),
    props: gon.vueProps
  },
  {
    path: '/places/:id/public',
    component: () => import(
      /* webpackChunkName: 'components/places/public' */
      '~/components/place-map-point-item-preview'
    ),
    props: gon.vueProps
  },
  {
    path: '/places/:id/heatmaps',
    component: () => import(
      /* webpackChunkName: 'components/places/heatmaps/index' */
      '~/places/heatmaps/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/organizations/:id/delegate_places_setting',
    component: () => import(
      /* webpackChunkName: 'components/organizations/delegate_places_settings/show' */
      '~/organizations/delegate_places_settings/show'
    ),
    props: gon.vueProps
  },
  {
    path: '/admin/pre_download_urls',
    component: () => import(
      /* webpackChunkName: 'components/admin/pre_download_urls/index' */
      '~/admin/pre_download_urls/index'
    ),
    props: gon.vueProps
  },

  {
    path: '/signed_urls',
    component: () => import(
      /* webpackChunkName: 'components/signed_urls/index/index' */
      '~/signed_urls/index/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/signed_urls/new',
    component: () => import(
      /* webpackChunkName: 'components/signed_urls/form/index' */
      '~/signed_urls/form/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/signed_urls/:id/edit',
    component: () => import(
      /* webpackChunkName: 'components/signed_urls/form/index' */
      '~/signed_urls/form/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/signed_urls/:id',
    component: () => import(
      /* webpackChunkName: 'components/signed_urls/show/index' */
      '~/signed_urls/show/index'
    ),
    props: gon.vueProps
  },
  {
    path: '/admin/device_commands',
    component: () => import(
      /* webpackChunkName: 'components/admin/device_commands/index' */
      '~/admin/device_commands/index'
    ),
    props: gon.vueProps,
    beforeEnter: (to, from, next) => {
      Nurve.$store.registerModule('device_commands', device_commands_module)
      next()
    }
  },
  {
    path: '/attributes_schemas/:id/edit',
    component: () => import(
      /* webpackChunkName: 'components/attributes_schemas/edit/attributes-schemas-edit' */
      '~/attributes_schemas/edit/attributes-schemas-edit'
    ),
    props: gon.vueProps,
  },
  {
    path: '/photographing_orders/:photographing_orders/archive_photographing_orders/:id',
    component: () => import(
      /* webpackChunkName: 'components/photographing_orders/archive_photographing_orders/show/index' */
      '~/photographing_orders/archive_photographing_orders/show/index'
    ),
    props: gon.vueProps,
  },
  {
    path: '/vr_tour',
    component: () => import(
      /* webpackChunkName: 'components/signed_urls/components/vr-tour-join-code-app' */
      '~/signed_urls/components/vr-tour-join-code-app'
    ),
    props: gon.vueProps,
    beforeEnter: (to, from, next) => {
      window.logger = createLogger()

      next()
    }
  },  
]
