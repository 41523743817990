export default class {
  constructor(data = {}) {
    this.id = data.id
    this.message = data.message
    this.status = data.status
  }

  get notifyMessage() {
    return `ID: ${this.id} ${this.message}`
  }

  get allowDismiss() {
    return this.status === 'succeeded' || this.status === 'failed'
  }

  get variant() {
    return this.status === 'succeeded' ? 'success' : 'info'
  }

  get needToPolling() {
    return this.status !== 'succeeded'
  }
}
