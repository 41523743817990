import * as Rx from 'rxjs'
import * as RxOperators from 'rxjs/operators'

export class ViewerMessageTranslator {
  constructor() {
    this.messageSubject = new Rx.Subject()
  }

  notify(msg) {
    if (msg.viewer_message === null || msg.viewer_message === undefined)
      return

    this.messageSubject.next(msg.viewer_message)
  }

  onCommandResponseAsObservable(commandId) {
    return this.messageSubject
      .pipe(
        RxOperators.filter(msg => this.isDefined(msg.command_response)),
        RxOperators.filter(msg => msg.command_response.command_id === commandId),
        RxOperators.map(msg => msg.command_response)
      )
  }

  onGreetingMessageAsObservable() {
    return this.messageSubject
      .pipe(
        RxOperators.filter(msg => this.isDefined(msg.greeting)),
        RxOperators.map(msg => msg.greeting)
      )
  }

  onCachePlaceProgressAsObservable() {
    return this.messageSubject
      .pipe(
        RxOperators.filter(msg => this.isDefined(msg.cache_place_progress)),
        RxOperators.map(msg => msg.cache_place_progress)
      )
  }

  onPrefsMessageAsObservable() {
    return this.messageSubject
      .pipe(
        RxOperators.filter(msg => this.isDefined(msg.prefs)),
        RxOperators.map(msg => msg.prefs)
      )
  }

  isDefined(prop) {
    return prop !== null && prop !== undefined
  }
}
