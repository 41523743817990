import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'

export default class {
  static create(type, mediumable_id) {
    const uppy = new Uppy()

    uppy.use(AwsS3Multipart, {
      limit: 5,

      getChunkSize(file) {
        return 100 * 1024 * 1024
      },

      createMultipartUpload(file) {
        const params = {
          medium_file_name: file.name,
          medium_content_type: file.type,
          medium_file_size: file.size,
          use_for: file.meta.use_for,
          mediumable_id: mediumable_id
        }

        return Nurve.$http.post(`/media/${type}`, params)
        .then(response => {
          const data = response.data
          file.meta = Object.assign(file.meta, { medium_id: data.medium_id })

          const result = {
            key: data.key,
            uploadId: data.upload_id
          }
          return Promise.resolve(result)
        })
      },

      listParts(file, { uploadId, key }) {
        return Nurve.$http.get(`/media/${type}/${file.meta.medium_id}/uploads/${uploadId}/parts`)
        .then(response => {
          return Promise.resolve(response.data.parts)
        })
      },

      prepareUploadParts(file, { uploadId, key, parts }) {
        const partNumbersParam = parts.map(part => part.number).join(',')
        return Nurve.$http.post(`/media/${type}/${file.meta.medium_id}/uploads/${uploadId}/parts/${partNumbersParam}`)
        .then(response => {
          return Promise.resolve(response.data)
        })
      },

      abortMultipartUpload(file, { uploadId, key }) {
        return Nurve.$http.delete(`/media/${type}/${file.meta.medium_id}/uploads/${uploadId}`)
      },

      completeMultipartUpload(file, { key, uploadId, parts }) {
        parts = parts.sort((pre, cur) => {
          return pre.PartNumber - cur.PartNumber
        })

        const params = {
          etags: parts.map(item => item.ETag)
        }

        return Nurve.$http.post(`/media/${type}/${file.meta.medium_id}/uploads/${uploadId}/done`, params)
      }
    })

    return uppy
  }
}
