import '~/setup/steps/styles'
import '~/setup/steps/expose'
import '~/setup/steps/logging'
import '~/setup/steps/error-handling'
import '~/setup/steps/request-client'
import { Vue, store } from '~/setup/steps/vue'
import { createPinia, PiniaVuePlugin } from 'pinia'

import createI18n from '~/locale/i18n'
import createI18nNew from '~/locale/i18n-new'
import router from '~/router'

import createLogger from '~/common/logger/logger-factory'
window.logger = createLogger()

import { VueHammer } from 'vue2-hammer'
import VueAddEventListener from 'vue-add-event-listener'

import VrControlModule from '~/vr_controls/store/module'

import DocumentReady from 'document-ready-promise'
import { boolean } from 'boolean'

import NurveBase from '~/components/nurve-base'
import RegistSteps from '~/components/regist-steps'
import PlaceQr from '~/components/place-qr'
import UploadArea from '~/components/upload-area'
import AutoSuggest from '~/components/auto-suggest'
import EasyRegistrationsImport from '~/components/easy-registrations-import'
import ExcelImportForms from '~/components/excel-import-forms.vue'
import SupportBrowsers from '~/components/support-browsers'
import SharedOrganizationGroup from '~/components/shared-organization-group'
import SharedOrganizationGroups from '~/components/shared-organization-groups'
import LicenseQr from '~/components/license-qr'
import InputInqueryEmails from '~/components/input-inquiry-emails'
import InputSchemaAttribute from '~/components/input-schema-attribute'
import InputFilterList from '~/components/input-filter-list'
import PointOrders from '~/components/point-orders'
import PlaceForm from '~/components/place-form'
import FactsheetDroparea from '~/components/factsheet-droparea'
import SearchConditionCollapse from '~/components/search-condition-collapse'
import AccountPermissions from '~/components/account-permissions'
import AdminSignedUrlModal from '~/components/admin-signed-url-modal'
import Tooltip from '~/components/tooltip'
import BreadcrumbList from '~/components/breadcrumb-list'

import './vue-wrapper.scss'

Promise.all([DocumentReady(), store.dispatch('contexts/fetch')]).then(async () => {
  store.registerModule('vr_control', VrControlModule)

  const vm = new Vue({
    el: '#nurve-base',
    store: store,
    render: h => h(NurveBase, {}),
  })

  Nurve.ready.resolve({ Vue, store, vm })

  // vue-router の定義内で (window.)vm を参照している場合があるので wait を入れる。
  // https://nurveteam.slack.com/archives/C01UB0GQX5W/p1690293283901649
  await Vue.nextTick()

  if (Rails.csrfToken()) {
    store.dispatch('contexts/setCSRFToken', Rails.csrfToken())
  }

  // テストでは jQuery のアニメーションを無効化。
  const env = store.state.contexts.meta.get('env')
  if(env == 'test') {
    $.fx.off = true
  }

  Vue.use(VueAddEventListener)
  Vue.use(VueHammer)

  Vue.use(PiniaVuePlugin)
  const pinia = createPinia()

  const wrapper_el = store.state.contexts.meta.get('wrapper-component')
  if(wrapper_el && document.querySelector(wrapper_el)) {
    const i18n = gon.vueRouter?.localesFromContext ? createI18nNew(store) : createI18n(gon.vueProps?.locale || 'ja')

    new Vue({
      el: wrapper_el,
      store: store,
      pinia: pinia,
      router: router,
      i18n: i18n,
      props: gon.vueWrapper?.props ? Object.keys(gon.vueWrapper.props) : [],
      propsData: gon.vueWrapper?.props,
      components: {
        RegistSteps, PlaceQr, UploadArea, AutoSuggest, ExcelImportForms, EasyRegistrationsImport,
        SupportBrowsers, SharedOrganizationGroup, SharedOrganizationGroups, Tooltip, LicenseQr,
        InputInqueryEmails, InputSchemaAttribute, PointOrders, InputFilterList,
        PlaceForm, FactsheetDroparea, SearchConditionCollapse, AccountPermissions,
        AdminSignedUrlModal, BreadcrumbList
      },

      provide() {
        return {
          axios: Nurve.$http
        }
      }
    })
  }

  await Vue.nextTick()

  // wrapper component を使用している場合は、body に invisible クラスが設定されているので除去。
  $('body').removeClass('invisible')

  $('.nav-item .disabled').on('click', () => {
    return false
  })
})
