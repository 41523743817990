import socketio from 'socket.io-client'

import * as Rx from 'rxjs'
import * as RxOperators from 'rxjs/operators'

import ApiAgent from '~/common/helpers/api_agent'
import { ViewerMessageTranslator } from './viewer_message_translator'

function createSocket(websocketUri, channelId) {
  const websocketOptions = {
    forceNew: true,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    transports: ['websocket', 'polling'],
  }
  return socketio(
    websocketUri,
    Object.assign({ query: `id=${channelId}` }, websocketOptions)
  )
}

let socketSubscriptions = new Rx.Subscription()
const viewerMessageTranslator = new ViewerMessageTranslator()

// store の state に入れてしまうと、Vuexのstrict: waringが出るので一旦外だし。
let socket = null

export default {
  namespaced: true,

  state: {
    device: { fetchStatus: null },
    isDeviceConnected: false,
    cachingPlaceId: null,
    cachingPlaceProgress: { state: 'none' },
    greetingMessage: null,
    viewerPrefs: null,
  },

  mutations: {
    setDevice(state, device) {
      state.device = device
    },
    setDeviceConnected(state, connected) {
      state.isDeviceConnected = connected
    },
    setGreetingMessage(state, message) {
      state.greetingMessage = message
    },
    setCachingPlaceId(state, id) {
      state.cachingPlaceId = id === null ? null : parseInt(id)
    },
    updateCachingPlaceProgress(state, progress) {
      state.cachingPlaceProgress = progress
    },
    setViewerPrefs(state, prefs) {
      state.viewerPrefs = prefs
    }
  },

  getters: {
    isDeviceFound: function (state) {
      return state.device.fetchStatus === 'found'
    },
  },

  actions: {
    selectDevice(context, query) {
      context.dispatch('resetDevice')

      return ApiAgent.get('/admin/device_commands/find_device', {
        id: query.deviceId,
        mdm_id: query.mdmId,
      })
        .then((device) =>
          context.commit('setDevice', { fetchStatus: 'found', ...device })
        )
        .catch((err) => {
          context.commit('setDevice', { fetchStatus: 'not_found', id: deviceId })
          throw err
        })
    },
    resetDevice(context) {
      context.commit('setCachingPlaceId', null)
      context.commit('updateCachingPlaceProgress', { state: 'none' })
      context.commit('setDevice', { fetchStatus: null })
      context.commit('setGreetingMessage', null)
      if (socket !== null) {
        socket.close()
        socketSubscriptions.unsubscribe()
        socketSubscriptions = new Rx.Subscription()
      }
      socket = null
      context.commit('setDeviceConnected', false)
      context.commit('setViewerPrefs', null)
    },
    async connectDevice(context, websocketUri) {
      socket = createSocket(websocketUri, context.state.device.id)
      context.dispatch('updateDeviceConnectionState')

      socketSubscriptions.add(
        viewerMessageTranslator
          .onCachePlaceProgressAsObservable()
          .subscribe((msg) => context.dispatch('updatePlaceCachingProgress', msg))
      )

      socket.on('message', (msg) => viewerMessageTranslator.notify(msg))
    },
    updateDeviceConnectionState(context) {
      const commandId = new Date().getTime() + ''

      const disconnectedTimer = Rx.of(1)
        .pipe(
          RxOperators.delay(3000)
        )
        .subscribe(() => {
          context.commit('setDeviceConnected', false)
        })

      socketSubscriptions.add(
        viewerMessageTranslator
          .onCommandResponseAsObservable(commandId)
          .pipe(
            RxOperators.take(1)
          )
          .subscribe(() => context.commit('setDeviceConnected', true))
      )

      socketSubscriptions.add(
        viewerMessageTranslator
          .onGreetingMessageAsObservable()
          .pipe(
            RxOperators.take(1)
          )
          .subscribe((msg) => {
            context.commit('setGreetingMessage', msg)
            disconnectedTimer.unsubscribe()
          })
      )

      socket.emit('message', {
        viewer_command: {
          greeting: { command_id: commandId },
        },
      })
    },
    async cachePlace(context, placeId) {
      const commandId = new Date().getTime()
      context.commit('setCachingPlaceId', placeId)
      context.commit('updateCachingPlaceProgress', {
        state: 'sending',
      })
      socket.emit('message', {
        viewer_command: {
          cache_place: { command_id: commandId, place_id: placeId },
        },
      })
    },
    updatePlaceCachingProgress(context, cachePlaceProgress) {
      if (cachePlaceProgress.place_id !== context.state.cachingPlaceId) {
        return
      }
      context.commit('updateCachingPlaceProgress', {
        state: cachePlaceProgress.state,
        progressRatio: cachePlaceProgress.progress_ratio,
      })
    },
    getPrefs(context) {
      const commandId = new Date().getTime()
      socketSubscriptions.add(
        viewerMessageTranslator
          .onPrefsMessageAsObservable()
          .subscribe((msg) => context.commit('setViewerPrefs', msg))
      )
      socket.emit('message', {
        viewer_command: {
          get_prefs: { command_id: commandId },
        },
      })
    },
    muteOpentokSpeaker(context, mute) {
      socket.emit('message', {
        viewer_command: {
          opentok_speaker: { mute: mute },
        },
      })
      context.dispatch('getPrefs')
    },
    muteOpentokMicrophone(context, mute) {
      socket.emit('message', {
        viewer_command: {
          opentok_mic: { mute: mute },
        },
      })
      context.dispatch('getPrefs')
    },
  },
}
