// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.right-bottom[data-v-0e21bac7] {
  position: absolute;
  top: auto;
  left: auto;
  right: 0;
  bottom: 0;
  transform: none;
}
`, "",{"version":3,"sources":["webpack://./app/javascript/src/components/thumbnail.vue"],"names":[],"mappings":";AA0BA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;AACA","sourcesContent":["<template>\n  <div class=\"thumbnail\">\n    <div class=\"embed-responsive embed-responsive-1by1\">\n      <div class=\"embed-responsive-item\">\n        <img ref=\"thumbImage\" :src=\"imageSrc\">\n        <div :class=\"slotCenter ? '' : 'right-bottom'\">\n          <slot />\n        </div>\n      </div>\n    </div>\n  </div>\n</template>\n\n<script>\nexport default {\n  props: ['imageSrc', 'imgData', 'slotCenter'],\n\n  mounted() {\n    for (let key in this.imgData) {\n      this.$refs['thumbImage'].dataset[key] = this.imgData[key]\n    }\n  }\n}\n</script>\n\n<style scoped>\n.right-bottom {\n  position: absolute;\n  top: auto;\n  left: auto;\n  right: 0;\n  bottom: 0;\n  transform: none;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
