export default {
  mounted() {
    this.cacheParentName(this.schemaValues)
  },
  computed: {
    autoCompleteValues() {
      if (this.subTree) return this.filteredCandidates(this.subTree)
      return this.getValuesInNestDepth(this.schemaValues)
    },
    childSubTree() {
      if (!this.subTree || !this.currentValue || this.currentValue === '') return null
      return this.subTree[this.currentValue] || []
    }
  },
  watch: {
    subTree(newSubTree) {
      if (newSubTree && !this.filteredCandidates(newSubTree).includes(this.currentValue)) this.currentValue = null
    }
  },
  methods: {
    getAttributeValue() {
      const jsonAttrValues = JSON.parse(this.attributeValues)
      return jsonAttrValues[this.attrName]
    },
    filteredCandidates(subTree) {
      return Array.isArray(subTree) ? this.subTree : Object.keys(subTree)
    },
    getValuesInNestDepth(object, depth = 0) {
      if (depth === this.nest) {
        if (typeof object === 'string') return []
        if (Array.isArray(object)) return object
        return Object.keys(object)
      } else {
        let list = []
        Object.keys(object).forEach(key => list = list.concat(this.getValuesInNestDepth(object[key], depth + 1)))
        return list
      }
    },
    cacheParentName(obj, parentName = '') {
      if (Array.isArray(obj)) {
        obj.forEach(elem => this.parentNameCache[elem] = parentName)
      } else {
        Object.keys(obj).forEach(key => {
          this.parentNameCache[key] = parentName
          this.cacheParentName(obj[key], key)
        })
      }
    },
    onChildValueChanged(_, value, currentValues) {
      if (this.parentNameCache[value]) this.currentValue = this.parentNameCache[value]
      this.currentValues = Object.assign({}, currentValues)
      this.onChange()
    },
    onSubmit() {
      this.onChange()
      this.$emit('submitted')
    },
    onChange() {
      this.currentValues[this.attrName] = this.currentValue
      this.$emit('value-changed', this.attrName, this.currentValue, this.currentValues)
    }
  }
}
