import VueI18n from 'vue-i18n'

export default store => {
  return new VueI18n({
    locale: store.getters['contexts/defaultLocale'],
    fallbackLocale: 'ja',
    silentTranslationWarn: true,
    messages: store.state.contexts.locales,
  })
}
