export default class {
  id: number
  key: string
  mdm_id: string

  constructor(data = {}) {
    Object.assign(this, data)
  }

  get displayName(): string {
    return this.mdm_id ? 'MDM' + ('00000' + this.mdm_id).slice(-5) : this.key.slice(0, 8)
  }

  get isDummy(): boolean {
    return false
  }
}
