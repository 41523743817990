console.log('setup/vue')

import Vue from 'vue'
Vue.config.productionTip = process.env.NODE_ENV !== 'production'

// 本番環境以外ではVue devtoolsを有効
Vue.config.devtools = (process.env.RAILS_ENV ?? 'production') !== 'production'
Vue.config.devtools = true

// Logger
Vue.use(Nurve.log)

// Vue ErrorHandling
Vue.config.errorHandler = (err, vm, info) => {
  Nurve.log.error(err, vm, info)
}

Vue.config.warnHandler = (msg, vm, trace) => {
  Nurve.log.warn(msg, vm, trace)
}

// Router
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Moment
import VueMoment from 'vue-moment'
Vue.use(VueMoment)

// Vue Screen
import VueScreen from 'vue-screen'
Vue.use(VueScreen, 'bootstrap')

// i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// axios
import VueAxios from 'vue-axios'
Vue.use(VueAxios, Nurve.$http)

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// font-awesome
import { library, dom, config } from "@fortawesome/fontawesome-svg-core"

// font-awesomeのアイコンが一瞬大きく表示されることがあることを防止するため、
// この時点でCSSを明示的に読み込む。
// https://nogulog.com/next-react-fontawesome-big-icon/
config.autoAddCss = false
import "@fortawesome/fontawesome-svg-core/styles.css"

import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, far)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('fa', FontAwesomeIcon)
dom.watch()

// Store
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
})
Nurve.$store = store

import contexts_module from '~/setup/store/contexts'
store.registerModule('contexts', contexts_module)

export { Vue, store }
